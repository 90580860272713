import React from "react";
// Customizable Area Start
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  CircularProgress
} from "@material-ui/core";
import ProfileSummaryView from "../../../components/src/ProfileSummaryView.web";
import MainContainer from "../../../components/src/containers/MainContainer.web";
import { SearchIcon } from "../../../components/src/assets/images";
import TopStudentsMenu from "../../../components/src/TopStudentsMenu";
import BorderColoredTextfield from "../../../components/src/BorderColoredTextfield";
import {Form, Formik} from "formik";
import { placeholderImage, profilePlaceholderImage } from "./assets";
import { classNames } from "../../../components/src/utilities/Utils";

import "./main.css";

// Customizable Area End
import FriendListController, {
  Props,
  configJSON,
} from "./FriendListController";
import Pagination from "../../../components/src/Pagination.web";

export default class FriendList extends FriendListController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    this.fetchGrades();
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <MainContainer className="friend-list-block">
        <div className="student-list-filters-container">
          <div className="student-list-filters">
            <span className="page-title">Social Media</span>
            {
              this.state.listType === "students" &&
              <div className="student-filter-select-boxes">
                <FormControl variant="outlined" className="student-filter-select-box">
                  <InputLabel><span className="select-word">Select</span>Class</InputLabel>
                  <Select
                      value={this.state.grade_id}
                      onChange={this.onGradeSelected}
                      name="class"
                      data-test-id="class-selectbox"
                  >
                    {this.state.grades.map((grade) => (
                      <MenuItem key={grade.id} value={grade.id}>{grade.name}</MenuItem>
                    ))}
                  </Select>

                  {this.state.loading === 'GRADES' && (
                    <div className="progress-icon">
                      <CircularProgress size={20} />
                    </div>
                  )}
                </FormControl>

                <FormControl variant="outlined" className="student-filter-select-box">
                    <InputLabel><span className="select-word">Select</span> Division</InputLabel>
                    <Select
                      value={this.state.division_id}
                      onChange={this.onDivisionSelect}
                      name="division"
                      data-test-id="division-selectbox"
                    >
                      {this.state.divisions.map((division) => (
                        <MenuItem key={division.id} value={division.id}>{division.name}</MenuItem>
                      ))}
                    </Select>

                    {this.state.loading === 'DIVISIONS' && (
                      <div className="progress-icon">
                        <CircularProgress size={20} />
                      </div>
                    )}
                </FormControl>
            </div>
            }
          </div>

          <Button
            data-test-id="goToProfile-button"
            onClick={this.goToProfile}
            variant="contained"
            color="primary"
            className="my-profile-button"
          >
            My Profile
          </Button>
        </div>
        <div className="student-list-container">
          <div
            className={classNames({
              'student-list-wrapper': true,
              '__has-users': this.state.users.length > 0
            })}
            style={{ width: "100%"}}
          >
            <Formik
                data-test-id="formik"
                initialValues={{search:""}}
                onSubmit={(values, actions) => {
                    this.handleSearch(values);
                    actions.setSubmitting(false);
                    actions.resetForm()
                }}
            >
              <Form className="student-search-form" translate="yes">
                <div className="border-colored-text-field-container">
                  <BorderColoredTextfield
                    className="student-search-input"
                    name="search"
                    placeholder={`Search by ${this.state.listType}, name…`}
                    icon={
                      <img
                        className="student-search-icon"
                        src={SearchIcon}
                        alt="search-icon"
                      />
                    }
                  />
                </div>
              </Form>
            </Formik>

              {(this.state.users.length > 0) ? (
                <div className="content-wrapper">
                  <div className="user-list-wrapper">
                    {this.state.users.map((user) => {

                      if (
                        this.state.searchValue.length > 0 &&
                        user.fullname.toLocaleLowerCase().indexOf(this.state.searchValue.toLocaleLowerCase()) === -1
                      ) {
                        return null;
                      }

                      return (
                        <div className="user-list-item" key={user.id}>
                          <Button data-test-id="handle-navigate" onClick={()=>this.handleNavigate(user)} className="user-list-item-card">
                            <ProfileSummaryView
                              profileType="unset"
                              className="profile-widget-item"
                              profilePicture={user.profilePicture || profilePlaceholderImage}
                              name={user.fullname}
                              title={`${user.postCount} Posts`}
                              isVerifiedUser={false}
                              totalImagesCount={user.totalImagesCount}
                              totalFilesCount={user.totalFilesCount}
                              totalVideosCount={user.totalVideosCount}
                              totalGoalsCount={user.totalGoalsCount}
                              size="narrow"
                            />
                          </Button>
                        </div>  
                      )
                    })}
                  </div>

                  <div className="pagination-wrapper">
                    <Pagination
                      count={this.state.totalPagesOfUserList}
                      page={this.state.activePageNumber}
                      onChange={this.onPageChanged}
                    />
                  </div>
                </div>
              ): (
                <div className="friend-list-placeholder">
                  <img src={placeholderImage} width={205} />
                  <span>No data available, Please select the class and Division.</span>
                </div>
              )}
            </div>

            <TopStudentsMenu students={this.state.topStudents} />
          </div>
      </MainContainer>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
