import React, {Component} from 'react';
import SinglePost, { IPost } from './SinglePost';
import { IProps as IProfileSummaryViewProps } from "./ProfileSummaryView.web";
import { ACTIVE_LANDING_PAGE_TAB } from "./types";
import CreateGoalWidget from './CreateGoalWidget';

type MyProps = {
    posts: IPost[];
    user:Omit<IProfileSummaryViewProps, "size"> & { biography: string };
    activeTab:ACTIVE_LANDING_PAGE_TAB;
    handleHidePost:(id:number)=>void;
    handleCreatePost:(files:any,images:string[],text:string)=>void;
}
type MyState = {}

class GoalsTab extends Component<MyProps,MyState> {
    render(){
        if(this.props.activeTab === 'GOALS') {
            return(
                <>
                    {
                      this.props.user.profileType === "myProfile" &&
                      <CreateGoalWidget
                        handleCreatePost={this.props.handleCreatePost}
                      />
                    }

                    {this.props.posts.map((item: IPost) => (
                      <SinglePost
                        hidePost={this.props.handleHidePost}
                        activeTab={this.props.activeTab}
                        createPost
                        key={item.id}
                        item={item}
                      />
                    ))}
                </>
            )
        }
        else return <></>
    }
}

export default GoalsTab
