Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
const Man1Photo = require('../assets/man1.png');
const Woman1Photo = require('../assets/woman1.png');
const CommentOne = require("../assets/comments.png");
const CommentTwo = require("../assets/comments-2.png");
const CommentThree = require("../assets/comments-3.png");
const CommentFour = require("../assets/comments-4.png");
const CommentFive = require("../assets/comments-5.png");

//c
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "FriendList";
exports.labelBodyText = "FriendList Body";

exports.btnExampleTitle = "CLICK ME";
exports.dummyData = [
  { profilePicture: Man1Photo, fullname: 'Ganesh Aacharya', postCount: 200, totalFilesCount: 18, totalGoalsCount: 89, totalImagesCount: 54, totalVideosCount: 24 },
  { profilePicture: Man1Photo, fullname: 'Vikram Batra', postCount: 270, totalFilesCount: 24, totalGoalsCount: 33, totalImagesCount: 85, totalVideosCount: 24 },
  { profilePicture: Woman1Photo, fullname: 'Shaloni Rajput', postCount: 200, totalFilesCount: 55, totalGoalsCount: 51, totalImagesCount: 82, totalVideosCount: 44 },
  { profilePicture: Man1Photo, fullname: 'Prabhu Pastel', postCount: 264, totalFilesCount: 75, totalGoalsCount: 21, totalImagesCount: 42, totalVideosCount: 41 },
  { profilePicture: Woman1Photo, fullname: 'Samantha', postCount: 624, totalFilesCount: 45, totalGoalsCount: 11, totalImagesCount: 22, totalVideosCount: 11 },
  { profilePicture: Man1Photo, fullname: 'Ganesh Aacharya', postCount: 200, totalFilesCount: 18, totalGoalsCount: 89, totalImagesCount: 54, totalVideosCount: 24 },
  { profilePicture: Man1Photo, fullname: 'Vikram Batra', postCount: 270, totalFilesCount: 24, totalGoalsCount: 33, totalImagesCount: 85, totalVideosCount: 24 },
  { profilePicture: Woman1Photo, fullname: 'Shaloni Rajput', postCount: 200, totalFilesCount: 55, totalGoalsCount: 51, totalImagesCount: 82, totalVideosCount: 44 },
  { profilePicture: Man1Photo, fullname: 'Prabhu Pastel', postCount: 264, totalFilesCount: 75, totalGoalsCount: 21, totalImagesCount: 42, totalVideosCount: 41 },
  { profilePicture: Woman1Photo, fullname: 'Samantha', postCount: 624, totalFilesCount: 45, totalGoalsCount: 11, totalImagesCount: 22, totalVideosCount: 11 },
  { profilePicture: Man1Photo, fullname: 'Ganesh Aacharya', postCount: 200, totalFilesCount: 18, totalGoalsCount: 89, totalImagesCount: 54, totalVideosCount: 24 },
  { profilePicture: Man1Photo, fullname: 'Vikram Batra', postCount: 270, totalFilesCount: 24, totalGoalsCount: 33, totalImagesCount: 85, totalVideosCount: 24 },
  { profilePicture: Woman1Photo, fullname: 'Shaloni Rajput', postCount: 200, totalFilesCount: 55, totalGoalsCount: 51, totalImagesCount: 82, totalVideosCount: 44 },
  { profilePicture: Man1Photo, fullname: 'Prabhu Pastel', postCount: 264, totalFilesCount: 75, totalGoalsCount: 21, totalImagesCount: 42, totalVideosCount: 41 },
  { profilePicture: Woman1Photo, fullname: 'Samantha', postCount: 624, totalFilesCount: 45, totalGoalsCount: 11, totalImagesCount: 22, totalVideosCount: 11 },
  { profilePicture: Man1Photo, fullname: 'Ganesh Aacharya', postCount: 200, totalFilesCount: 18, totalGoalsCount: 89, totalImagesCount: 54, totalVideosCount: 24 },
  { profilePicture: Man1Photo, fullname: 'Vikram Batra', postCount: 270, totalFilesCount: 24, totalGoalsCount: 33, totalImagesCount: 85, totalVideosCount: 24 },
  { profilePicture: Woman1Photo, fullname: 'Shaloni Rajput', postCount: 200, totalFilesCount: 55, totalGoalsCount: 51, totalImagesCount: 82, totalVideosCount: 44 },
  { profilePicture: Man1Photo, fullname: 'Prabhu Pastel', postCount: 264, totalFilesCount: 75, totalGoalsCount: 21, totalImagesCount: 42, totalVideosCount: 41 },
  { profilePicture: Woman1Photo, fullname: 'Samantha', postCount: 624, totalFilesCount: 45, totalGoalsCount: 11, totalImagesCount: 22, totalVideosCount: 11 },
  { profilePicture: Man1Photo, fullname: 'Ganesh Aacharya', postCount: 200, totalFilesCount: 18, totalGoalsCount: 89, totalImagesCount: 54, totalVideosCount: 24 },
  { profilePicture: Man1Photo, fullname: 'Vikram Batra', postCount: 270, totalFilesCount: 24, totalGoalsCount: 33, totalImagesCount: 85, totalVideosCount: 24 },
  { profilePicture: Woman1Photo, fullname: 'Shaloni Rajput', postCount: 200, totalFilesCount: 55, totalGoalsCount: 51, totalImagesCount: 82, totalVideosCount: 44 },
  { profilePicture: Man1Photo, fullname: 'Prabhu Pastel', postCount: 264, totalFilesCount: 75, totalGoalsCount: 21, totalImagesCount: 42, totalVideosCount: 41 },
  { profilePicture: Woman1Photo, fullname: 'Samantha', postCount: 624, totalFilesCount: 45, totalGoalsCount: 11, totalImagesCount: 22, totalVideosCount: 11 },
];

exports.topStudents = [
  {
      id:1,
      name:"Samantha",
      image:CommentOne,
      grade:"Std. 11 A"
  },
  {
      id:2,
      name:"Vikram Batra",
      image:CommentTwo,
      grade:"Std. 11 A"
  },
  {
      id:3,
      name:"Mark Smith",
      image:CommentThree,
      grade:"Std. 11 A"
  },
  {
      id:4,
      name:"Calvin George",
      image:CommentFour,
      grade:"Std. 11 A"
  },
  {
      id:5,
      name:"Samantha",
      image:CommentOne,
      grade:"Std. 11 A"
  },
  {
      id:6,
      name:"Samantha",
      image:CommentFour,
      grade:"Std. 11 A"
  },
  {
      id:7,
      name:"Vikram Batra",
      image:CommentTwo,
      grade:"Std. 11 A"
  },
  {
      id:8,
      name:"John Doe",
      image:CommentThree,
      grade:"Std. 11 A"
  },
  {
      id:9,
      name:"Mark Smith",
      image:CommentThree,
      grade:"Std. 11 A"
  },
  {
      id:10,
      name:"Samantha",
      image:CommentOne,
      grade:"Std. 11 A"
  },
]

exports.getDivisionsApi = {
  contentType: { "Content-Type": "application/json" },
  endpointMethod: "bx_block_scheduling/grades/get_grade_divisions",
  apiMethod: "GET",
  baseEndpoint: 'https://institute-management.b91002.dev.us-east-1.aws.svc.builder.cafe'
}

exports.getGradesApi = {
  contentType: { "Content-Type": "application/json" },
  endpointMethod: "bx_block_scheduling/grades",
  apiMethod: "GET",
  baseEndpoint: 'https://institute-management.b91002.dev.us-east-1.aws.svc.builder.cafe'
}

exports.getStudentsByDivisionApi = {
  contentType: { "Content-Type": "application/json" },
  endpointMethod: "bx_block_scheduling/members/get_students_by_division",
  apiMethod: "POST",
  baseEndpoint: 'https://institute-management.b91002.dev.us-east-1.aws.svc.builder.cafe'
}

// Customizable Area End