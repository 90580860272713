import React from 'react';
import MuiPagination, { PaginationProps } from '@material-ui/lab/Pagination';
import { Button } from '@material-ui/core';
import { ArrowRightAltOutlined, KeyboardBackspaceOutlined } from '@material-ui/icons';
import { classNames } from './utilities/Utils';
import './assets/styles/Pagination.css';

class Pagination extends React.Component<PaginationProps> {
  render() {
    return (
      <MuiPagination
        className="custom-pagination"
        shape="rounded"
        variant="outlined"
        color="primary"
        {...this.props}
        renderItem={(item) => {
          if (item.type === 'previous' || item.type === 'next') {
            return (
              <Button
                className={`${item.type}Button`}
                variant="contained"
                color="primary"
                size={item.size}
                disabled={item.disabled}
                onClick={item.onClick}
                startIcon={item.type === 'previous' && <KeyboardBackspaceOutlined />}
                endIcon={item.type === 'next' && <KeyboardBackspaceOutlined />}
              >
                {item.type === 'previous' ? 'Previous' : 'Next'}
              </Button>
            )
          }

          return (
            <Button
              className={classNames({
                'page-button': true,
                '__selected': item.selected
              })}
              variant={item.selected === true ? 'contained' : 'outlined'}
              color="primary"
              size={item.size}
              onClick={item.onClick}
            >
              {item.page || '...'}
            </Button>
          )
        }}
      />
    )
  }
}

export default Pagination;