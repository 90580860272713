import { IUserInformation } from '../types';
import { safeJSONparse } from './Utils';

export const getUserInformation = (): (IUserInformation | null) => {
    if (typeof localStorage === 'undefined') { return null; }

    const storageData = localStorage.getItem('userInformation');
    if (!storageData) { return null }

    let data = null;

    try {
        data = safeJSONparse<IUserInformation>(storageData);
    } catch (err) {}

    return data;
}

export const getToken = (): string => {
    if (typeof localStorage === 'undefined') { return ''; }

    const token = localStorage.getItem('token');
    return token || '';
}