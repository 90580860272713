import React, {Component} from 'react';
import { Box, withWidth, isWidthDown, Theme, Button } from "@material-ui/core";
import { AddOutlined, CloseOutlined } from "@material-ui/icons";
import { classNames } from './utilities/Utils';
import "./assets/styles/topStudentsMenu.css"

export interface ITopStudent {
    id:number,
    name:string,
    image:string,
    grade:string,
}

type MyProps = {
    width: Parameters<Theme['breakpoints']['only']>[0],
    students: ITopStudent[]
}

type MyState = {
    isCollapsed: boolean
}

class TopStudentsMenu extends Component<MyProps,MyState> {
    constructor(props:any){
        super(props);

        this.handleCollapsible = this.handleCollapsible.bind(this);
        this.state = { isCollapsed: true }
    }

    handleCollapsible(isMobileView: boolean) {
        if (!isMobileView) return;

        this.setState((prevState) => ({
            ...prevState,
            isCollapsed: !prevState.isCollapsed
        }))
    }

    render(){
        const isMobileView = isWidthDown('sm', this.props.width, true);

        if (isMobileView && this.props.students.length === 0) {
            return null;
        }


        return (
            <Box
                component="div"
                className={classNames({
                    'top-students-menu-container': true,
                    '__mobile-view': isMobileView,
                    '__collapsed': this.state.isCollapsed,
                })}
                bgcolor="primary.main"
            >
                {isMobileView ? (
                    <Button
                        className="title-button"
                        variant="text"
                        fullWidth
                        onClick={() => this.handleCollapsible(isMobileView)}
                    >
                        {this.state.isCollapsed ?
                                <AddOutlined className="title-button-icon" />
                                : <CloseOutlined className="title-button-icon" />
                        }

                        Top 10 Students
                    </Button>
                ) : (
                    <h3>Top 10 Students</h3>
                )}

                {this.props.students.length > 0 ? (
                    <div className="top-students-list">
                        {this.props.students.map((item)=>(
                            <div className="top-students-card" key={item.id}>
                                <img src={item.image} alt=""/>
                                <div className="top-students-info">
                                    <h4>{item.name}</h4>
                                    <span>{item.grade}</span>
                                </div>
                            </div>
                        ))}
                    </div>
                ) : (
                    <div className="no-data-section">
                        No Data Available!
                    </div>
                )}

            </Box>
        );
    }
}

export default withWidth()(TopStudentsMenu);
